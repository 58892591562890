import { ApiPaths } from '../../../api/constants';
import { ProcessGeneral } from '../../../api/endpoints/processes';
import {
  InstanceCountData,
  ProcessInstanceDataModel,
  ProcessInstanceDetails,
  ProcessInstanceGeneral,
  ProcessInstanceToCreate,
} from '../../../api/endpoints/processInstance';
import { OdataURLBuilder } from '../../../api/types/OdataURLBuilder';
import ApiUrlParams from '../../../constants/apiUrlParams';
import BaseRepo from '../../BaseRepo';
import {
  buildBadRequestApiError,
  buildNotFoundApiError,
} from '../../helpers/errors';
import { ApiResponseAsync, isApiError } from '../../models';
import { convertSearchToOData } from '../../odata';
import { Patch } from '../../patch';
import { Filter, SearchRequest } from '../../search';
import {
  IProcessInstanceRepoV2,
  ProcessInstanceCreateResponse,
  ProcessInstanceSearchResult,
} from './IProcessInstanceRepo';

interface ProcessInstancesCreateServerRequest {
  instances: ProcessInstanceToCreate[];
}

interface ProcessInstanceCreateServerResponse {
  instances: ProcessInstanceGeneral[];
  errors?: string[];
}

export class ProcessInstanceRepoV2
  extends BaseRepo
  implements IProcessInstanceRepoV2
{
  search = (
    draft: ProcessGeneral,
    request: SearchRequest
  ): ApiResponseAsync<ProcessInstanceSearchResult> => {
    const link = draft.links
      ? draft.links['searchProcessInstances']
      : undefined;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "search process instances" is not available')
        )
      );
    }

    return this.helpers
      .executeSearch<ProcessInstanceSearchResult>(link, request, {
        responseStopTransformPaths: [
          'instances.variables',
          'stages.steps.tasks.task.params.mapper',
          'stages.steps.tasks.sub_tasks.params.mapper',
        ],
      })
      .then((searchResult) => {
        if (isApiError(searchResult)) {
          return searchResult;
        }
        if (!searchResult.instances) searchResult.instances = [];
        return searchResult;
      });
  };

  getAggregate = (
    draft: ProcessGeneral,
    filter?: Filter
  ): ApiResponseAsync<InstanceCountData> => {
    const link = draft.links
      ? draft.links['getProcessInstancesAggregation']
      : undefined;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "get process instances aggregation" is not available')
        )
      );
    }

    const request: SearchRequest = {
      filter,
    };

    return this.helpers.executeSearch<InstanceCountData>(link, request);
  };

  getCount = (
    draft: ProcessGeneral,
    filter: Filter
  ): ApiResponseAsync<number> => {
    const link = draft.links
      ? draft.links['searchProcessInstances']
      : undefined;
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "search process instances" is not available')
        )
      );
    }

    const searchRequest: SearchRequest = {
      count: true,
      filter: filter,
    };

    return this.helpers
      .executeSearch<number>(link, searchRequest)
      .then((searchResult) => {
        if (isApiError(searchResult)) {
          return searchResult;
        }
        return searchResult;
      });
  };

  deleteInstance = (
    instance: ProcessInstanceGeneral
  ): ApiResponseAsync<void> => {
    const link = instance.links?.['delete'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "delete process instance" is not available')
        )
      );
    }

    return this.helpers.handleDelete(link.href);
  };

  deleteAllInstancesPerFilter = (
    draft: ProcessGeneral,
    filter: Filter
  ): ApiResponseAsync<void> => {
    const link = draft.links?.['deleteProcessInstancesBulk'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "delete bulk process instances" is not available')
        )
      );
    }

    const builder = new OdataURLBuilder(link);
    builder.setFilter(convertSearchToOData({ filter }).filter!);
    return this.helpers.handleDelete(builder.toString());
  };

  createInstance = (
    draft: ProcessGeneral,
    variables: ProcessInstanceDataModel
  ): ApiResponseAsync<ProcessInstanceCreateResponse> =>
    this.createInstanceBase(draft, { variables });

  createInstanceByName = (
    draft: ProcessGeneral,
    name: string
  ): ApiResponseAsync<ProcessInstanceCreateResponse> =>
    this.createInstanceBase(draft, { name });

  private createInstanceBase = async (
    draft: ProcessGeneral,
    request: ProcessInstanceToCreate
  ): ApiResponseAsync<ProcessInstanceCreateResponse> => {
    const link = draft.links?.['createProcessInstances'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "create Process instances" is not available')
        )
      );
    }

    const response = await this.helpers.handlePost<
      ProcessInstancesCreateServerRequest,
      ProcessInstanceCreateServerResponse
    >(
      link.href,
      {
        instances: [request],
      },
      { requestStopTransformPaths: ['instances.variables'] }
    );
    if (isApiError(response)) return response;
    if (response.instances && response.instances.length > 0) {
      return {
        instance: response.instances[0],
        execution_error:
          response.errors && response.errors.length > 0
            ? response.errors[0]
            : undefined,
      };
    }

    return buildBadRequestApiError(new Error('undefined response from server'));
  };

  getById = async (
    draft: ProcessGeneral,
    instanceId: string
  ): ApiResponseAsync<ProcessInstanceGeneral> => {
    const request: SearchRequest = {
      top: 1,
      filter: {
        operator: 'AND',
        items: [
          { field: 'id', operation: 'equal', value: instanceId, not: false },
        ],
      },
    };
    const searchResult = await this.search(draft, request);
    if (isApiError(searchResult)) return searchResult;
    if (searchResult.instances.length === 0) return buildNotFoundApiError();
    return searchResult.instances[0];
  };

  updateVariables = async (
    instance: ProcessInstanceGeneral,
    request: Patch
  ): ApiResponseAsync<ProcessInstanceDetails> => {
    const link = instance.links?.['updateVariables'];
    if (!link) {
      return Promise.resolve(
        buildBadRequestApiError(
          new Error('link "update variables" is not available')
        )
      );
    }
    return this.helpers.executePatch(link, request, false);
  };

  getProcessInstanceDetails = async (
    draft: ProcessGeneral,
    instanceId: string
  ): ApiResponseAsync<ProcessInstanceDetails> => {
    const searchResult = await this.getById(draft, instanceId);
    if (isApiError(searchResult)) return searchResult;

    const link = searchResult.links?.['self'];
    if (!link) {
      return buildBadRequestApiError(
        new Error('link "get process instance self" is not available')
      );
    }

    return this.helpers.handleGet<ProcessInstanceDetails>(link.href, {
      responseStopTransformPaths: ['data_model.variables'],
    });
  };

  continueProcessInstance = async (
    instance: ProcessInstanceDetails,
    values: DynamicFormNormalizedParams
  ): ApiResponseAsync<void> => {
    const url = ApiPaths.processes.byId.instances.byId.continue._({
      [ApiUrlParams.releaseOrDraftProcessId]: instance.version.releaseId,
      [ApiUrlParams.processInstanceId]: instance.id,
    });

    return this.helpers.handlePostAction(url, { params: values });
  };

  skipProcessInstance = (
    instance: ProcessInstanceDetails
  ): ApiResponseAsync<ProcessInstanceDetails> => {
    const url = ApiPaths.processes.byId.instances.byId.skip._({
      [ApiUrlParams.releaseOrDraftProcessId]: instance.version.releaseId,
      [ApiUrlParams.processInstanceId]: instance.id,
    });

    return this.helpers.handlePost<string, ProcessInstanceDetails>(url, '');
  };
}
