import { combineReducers } from 'redux';

import { API_REQ_ACTION_TYPES } from 'api/actions';
import {
  GetInvitationsResult,
  InvitedUserGeneral,
} from 'api/endpoints/invitations';

export interface InvitationsState {
  list: InvitedUserGeneral[] | null;
}

const invitations = combineReducers<InvitationsState>({
  list(state = null, { type, payload }: ReduxAction<GetInvitationsResult>) {
    switch (type) {
      case API_REQ_ACTION_TYPES.INVITATIONS.GET_INVITATIONS.SUCCESS:
        return payload ? payload.data : null;
      default:
        return state;
    }
  },
});

export default invitations;
