import { DataModelFieldSubType } from 'constants/global';
import { MASKS, PLACEHOLDERS, VALIDATORS } from 'constants/masks';

import { string } from 'utils/normalizers';
import {
  isRequired,
  isValidName,
  rangeLength,
  Validator,
} from 'utils/validators';

const TEXTS = {
  ACCESS: 'Access',
  FIELDS: {
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    EMAIL: 'Email',
    PHONE_NUMBER: 'Phone number',
    SYSTEM_ROLE: 'Select Role in The System',
    ROLE: 'Select Tenant Role',
    TENANT: 'Select Tenant',
    PERMISSIONS: 'Select Permissions',
  },
  ALL_PERMISSIONS_ARE_SELECTED: 'All permissions are selected',
  ADD_TENANT: 'Add tenant',
  CANCEL: 'Cancel',
  SUBMIT: 'Save changes',
};

const PERMISSION = {
  allowDeleteInstance: {
    label: 'Allow Delete Instance',
    value: 'allowDeleteInstance',
  },
  allowUpdateVariables: {
    label: 'Allow Edit Final Data & Variables',
    value: 'allowUpdateVariables',
  },
  allowReprocessInstance: {
    label: 'Allow Reprocess Instance',
    value: 'allowReprocessInstance',
  },
  allowStartStopPolling: {
    label: 'Allow Start/Stop Polling',
    value: 'allowStartStopPolling',
  },
};

const ROLE = {
  admin: {
    label: 'Organization Admin',
    value: 'admin',
  },
  user: {
    label: 'User',
    value: 'user',
  },
};

const FIELDS = {
  FIRST_NAME: {
    name: 'firstName',
    label: TEXTS.FIELDS.FIRST_NAME,
    placeholder: TEXTS.FIELDS.FIRST_NAME,
    validate: [isRequired, isValidName],
  },
  LAST_NAME: {
    name: 'lastName',
    label: TEXTS.FIELDS.LAST_NAME,
    placeholder: TEXTS.FIELDS.LAST_NAME,
    validate: [isRequired, isValidName],
  },
  EMAIL: {
    name: 'email',
    label: TEXTS.FIELDS.EMAIL,
    placeholder: PLACEHOLDERS[DataModelFieldSubType.TextEmail],
    mask: MASKS[DataModelFieldSubType.TextEmail],
    validate: [
      isRequired,
      ...(VALIDATORS[DataModelFieldSubType.TextEmail] as Validator[]),
    ],
  },
  PHONE_NUMBER: {
    name: 'phoneNumber',
    label: TEXTS.FIELDS.PHONE_NUMBER,
    placeholder: PLACEHOLDERS[DataModelFieldSubType.TextPhone],
    validate: [isRequired, rangeLength(8, 15)],
    maxLength: 15,
    format: string.getOnlyNumbers,
  },
  SYSTEM_ROLE: {
    name: 'systemRole',
    label: TEXTS.FIELDS.SYSTEM_ROLE,
    placeholder: TEXTS.FIELDS.SYSTEM_ROLE,
    validate: isRequired,
    optionsList: Object.values(ROLE),
  },
  TENANT: {
    name: 'tenant',
    label: TEXTS.FIELDS.TENANT,
    placeholder: TEXTS.FIELDS.TENANT,
    validate: isRequired,
  },
  ROLE: {
    name: 'role',
    label: TEXTS.FIELDS.ROLE,
    placeholder: TEXTS.FIELDS.ROLE,
    validate: isRequired,
  },
  PERMISSIONS: {
    name: 'permissions',
    label: TEXTS.FIELDS.PERMISSIONS,
    placeholder: TEXTS.FIELDS.PERMISSIONS,
    options: Object.values(PERMISSION),
    entityName: 'Permission',
  },
  TENANTS: {
    name: 'tenants',
  },
};

const getChangedValue = (
  current: KeyValuePairs = {},
  prev: KeyValuePairs = {}
) =>
  Object.entries(current).find(
    ([key, value]) => value && prev[key] && prev[key] !== value
  );

const EMPTY_ROW = { tenant: {}, role: {}, permissions: null };

export { FIELDS, ROLE, TEXTS, EMPTY_ROW, getChangedValue, PERMISSION };
